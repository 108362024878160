import React, { useState, useEffect, useContext, Fragment, useCallback } from 'react'
import './Hotel.css';
import Store from '../Store/Store';
import Comment from '../Comment/Comment';
import CbmContext from '../../Context/Cbm/cbmContext';

const Hotel = ({ setPayLoad }) => {

  const cbmContext = useContext(CbmContext);
  const {stores, getPS, psList, portalUser, submittedData, errorCode, psListRetrieved } = cbmContext;
  const [hotelInfo, setHotelInfo] = useState({
    numOfBeds: null,
    store: null,
    newPS: null,
    newPSChecked: false,
    roomCountEntered: false,
    numOfRooms: null,
    ps1: null,
    ps2: null,
    workTicket: null,
    comments: '',
    portalUser
  });

  const [roomCountEntered, setRoomCountEntered] = useState(false);
  const handleBedCount = (e) => {
    let numBeds = hotelInfo.numOfRooms * e.target.value
    setHotelInfo({...hotelInfo, numOfBeds: numBeds});
  }

  const newPSChecked = useCallback(() => {
    let currentHotelInfo = {...hotelInfo};
    currentHotelInfo.newPSChecked = !currentHotelInfo.newPSChecked;
    setHotelInfo(currentHotelInfo);
  }, [hotelInfo])

  const handleStore = (e) => {
    let chosenStore = [];
    chosenStore = stores.filter(store => { return store.id === parseInt(e.target.value)})
    setHotelInfo({...hotelInfo, store: chosenStore[0]})    
  }

  const generatePSList = () => {
    const generatedList = psList?.map((ps, i) => {
      return <option key={i} name='ps' id='psList' value={ps.id}>{`${ps.firstName} ${ps.lastName}`}</option>
    })
    return generatedList;
  }

  const onHandleChange = (e) => {
    const {name, value} = e.target;
    let currentHotelInfo = {...hotelInfo};
    currentHotelInfo[name] = value;
    setHotelInfo(currentHotelInfo)
  }

  const onHandlePS = (e) => {
    const { name, value} = e.target;
    let currentHotelInfo = {...hotelInfo};
    if (currentHotelInfo.newPSChecked === true) {
    currentHotelInfo.newPS = value;
    currentHotelInfo.ps1 = null;
    currentHotelInfo.ps2 = null;
    setHotelInfo(currentHotelInfo)
  } else {
  let psFound = findPSById(e.target.value)
    currentHotelInfo.newPS = '';
    currentHotelInfo[name] = psFound[0]
    setHotelInfo(currentHotelInfo)
    }
  }

  const findPSById = (psID) => {
    let psFound = psList.filter(ps => {
      // if (ps.id === parseInt(psID)) {
      //   return ps;
      // }
      return ps.is === parseInt(psID) && ps;
    })
    return psFound;
  }

  useEffect(() => {
    if (psList.length === 0 && portalUser && psListRetrieved === false) {
      getPS(portalUser?.id);
    }

  }, [psList, getPS, portalUser?.id, portalUser, psListRetrieved])

  useEffect(() => {
    setPayLoad(hotelInfo);
  }, [setPayLoad, hotelInfo])

  useEffect(() => {
    let currentHotelInfo = {...hotelInfo};
    if (currentHotelInfo.newPSChecked === true && currentHotelInfo.ps1 !== null && currentHotelInfo.ps2 !== null) {
      currentHotelInfo.ps1 = null;
      currentHotelInfo.ps2 = null;
      setHotelInfo(currentHotelInfo);
    } else if (currentHotelInfo.newPSChecked === false && currentHotelInfo.newPS !== '') {
      currentHotelInfo.newPS = '';
      setHotelInfo(currentHotelInfo);
    }
  }, [hotelInfo])

  useEffect(() => {
    if (hotelInfo.numOfRooms > 0 && hotelInfo.numOfRooms !== null && hotelInfo.numOfRooms !== undefined) {
      setRoomCountEntered(true);
    } else if (hotelInfo.numOfBeds !== 0) {
      let currentHotelInfo = {...hotelInfo};
      currentHotelInfo['numOfBeds'] = 0;
      setHotelInfo(currentHotelInfo);
      setRoomCountEntered(false);
    }
  }, [hotelInfo.numOfRooms, hotelInfo])
  
  useEffect(() => {
    if (submittedData && submittedData !== null && errorCode !== '') {
      setHotelInfo(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setHotelInfo, errorCode])

  return (
    <Fragment>
        <div className='heading'>
          <h1>Hotel</h1>
      </div>
      <fieldset className='hotelStoreLocation'>
        <legend>Store Info</legend>
        <Store storeId={'hotelStoreLocationId'} storeValue={hotelInfo.store?.id} changeStoreInfo={handleStore} header="Location" storeList={stores} />
      </fieldset>
      <fieldset className='checkInOut'>
        <legend>Check In-Out Info</legend>
        <div className='checkIn'>
          <label htmlFor='checkIn' className='required'>Check in date: </label>
          <input type="date" id='checkIn' value={hotelInfo.checkIn} required name='checkIn' onChange={(e) => onHandleChange(e)} />
        </div>
        <div className='checkOut'>
          <label htmlFor='checkOut' className='required'>Check out date: </label>
          <input type="date" id='checkOut' value={hotelInfo.checkOut} required name='checkOut' onChange={(e) => onHandleChange(e)} />
        </div>
      </fieldset>
      <fieldset className='roomInfo'>
        <legend>Room Info</legend>
        <div className='roomsInputs'>
          <div className='numRooms' >
            <label htmlFor='numRooms' className='required'>How many rooms: </label>
            <input type='number' id='numRooms' required name='numOfRooms' value={hotelInfo.numOfRooms} onChange={(e) => onHandleChange(e)} />
          </div>
          { roomCountEntered &&
          <div className='numPeople'>
            <label htmlFor='numPeople' className='required'>How many people per room</label>
            <input type='number' id='numPeople' value={hotelInfo.numOfPeople} required name='numOfPeople' onChange={(e) => handleBedCount(e)} />
          </div>
          }
        </div>
      </fieldset>
      <fieldset className='psInfo'>
        <legend>PS Info</legend>
        <div className='newPs'>
          <label htmlFor='newPs' className='required'>PS: </label>
          <input type="checkbox" id='newPs' name='newPSChecked' value='newPS' onChange={() => newPSChecked()} />
        </div>
        { hotelInfo?.newPSChecked ?
        <div className='newPsInput'>
          <label htmlFor='newPsInput'>Enter new ps name:</label>
          <input type='text' id='newPsInput' value={hotelInfo.newPSChecked ? hotelInfo.newPS : ''} required={hotelInfo.newPSChecked} name='newPS' onChange={(e) => onHandlePS(e)} />
        </div>
        :
        <div className='currentPS'>
          <div className='ps1'>
            <label htmlFor='ps1'>Employee 1</label>
            <select id='ps1' required={!hotelInfo.newPSChecked} value={psList ? '' : !hotelInfo.newPSChecked && hotelInfo.ps1} name='ps1' onChange={(e) => onHandlePS(e)} >
              <option value=''>Choose first ps</option>
              {generatePSList()}
            </select>
          </div>
          <div className='ps2'>
            <label htmlFor='ps2'>Employee 2</label>
            <select id='ps2' required={!hotelInfo.newPSChecked} value={psList ? '' : !hotelInfo.newPSChecked && hotelInfo.ps2} name='ps2' onChange={(e) => onHandlePS(e)} >
              <option value=''>Choose second ps</option>
              {generatePSList()}
            </select>
          </div>
        </div>
        }
      </fieldset>
      <fieldset className='hotelInfo'>
        <legend>Hotel reason</legend>
        <div className='hotelInputs'>
          <div className='hotelReason'>
            <label htmlFor='hotelReason' className='required'>Reason for hotel: </label>
            <select id='hotelReason' value={hotelInfo.hotelReason} required name='hotelReason' onChange={(e) => onHandleChange(e)} >
              <option value="">Select an option</option>
              <option value="Vat">VATs</option>
              <option value="Full_Sr">Full SR</option>
              <option value="Partial_Sr">Partial SR</option>
              <option value="Full_Dsr">Full DSR</option>
              <option value="Partial_Dsr">Partial DSR</option>
              <option value="Full_Carpet_Extraction">Full Carpet Extraction</option>
              <option value="Covering_Location">Covering Location</option>
              <option value="Closing_Wo">Closing WOs</option>
              <option value="Store_Remodel">Store Remodel</option>
              <option value="Training">Training</option>
            </select>
          </div>
          <div className='workTicket'>
            <label htmlFor='workTicket' className='required'>Enter work ticket: </label>
            <input type='text' required value={hotelInfo.workTicket} id='workTicket' name='workTicket' onChange={(e) => onHandleChange(e)} />
          </div>
        </div>
        <div className='hotel-comments'>
          <Comment commentsId={'hotelCommentsId'} payload={hotelInfo.comments} onHandleChange={onHandleChange} />
        </div>
      </fieldset>
    </Fragment>
  )
}

Hotel.propTypes = {}

export default Hotel